import { I18n } from 'i18n-js';

import appTranslations from './translations/app.translations';
import { AvailableAppLocales, DefaultAppLocale } from './appLocale';

export const selectedAppLocale = (() => {
    const langs = navigator.languages;
    let locale = undefined;
    langs.forEach(lang => {
        AvailableAppLocales.forEach(appLocale => {
            if (!locale) {
                if (lang.startsWith(appLocale)) locale = appLocale;
            }
        });
    });
    locale ??= DefaultAppLocale;
    return locale;
})();

const translations = [appTranslations];

const combineTranslations = translations => {
    const result = {};
    translations.forEach(translation => {
        Object.entries(translation).forEach(([locale, trans]) => {
            result[locale] = { ...result[locale], ...trans };
        });
    });
    return result;
};

const combinedTranslations = combineTranslations(translations);
export const LocaleInstance = new I18n(combinedTranslations, {
    enableFallback: true,
    defaultLocale: selectedAppLocale,
    locale: selectedAppLocale,
});

export const t = (scope, opts) => LocaleInstance.translate(scope, opts);
