import { AppLocale } from '../language/appLocale';

import chessCover from '../images/games/chess-cover.png';
import chessRuCover from '../images/games/chess-ru-cover.webp';
import checkersEnCover from '../images/games/checkers-en-cover.webp';
import checkersEnCoverWithEnTitle from '../images/games/checkers-en-cover-with-en-title.webp';
import checkersRuCover from '../images/games/checkers-ru-cover.webp';
import checkersRuCoverWithRuTitle from '../images/games/checkers-ru-cover-with-ru-title.webp';
import checkersRuCoverWithEnTitle from '../images/games/checkers-ru-cover-with-en-title.webp';
import petsAndPaintsCover from '../images/games/pets-and-paints-cover.png';
import marketplaceCover from '../images/games/marketplace-cover.webp';
import retroRacerTurboCover from '../images/games/retro-racer-turbo-cover.webp';

import iconVk from '../images/platform/vk-icon.png';
import iconOk from '../images/platform/ok-icon.png';
import iconYg from '../images/platform/yandex-games-icon.jpeg';
import iconCrazyGames from '../images/platform/crazy-games-purple-icon.png';
import iconCrazyGamesLargePurple from '../images/platform/crazy-games-large-purple-icon.svg';
import iconGooglePlay from '../images/platform/google-play-icon.png';
import iconGM from '../images/platform/gamemonetize-icon.png';
import iconGamePix from '../images/platform/game-pix-icon.png';
import iconPoki from '../images/platform/poki-icon.webp';
import iconAppStore from '../images/platform/apple-app-store-icon.png';
import iconAppGallery from '../images/platform/huawei-app-gallery-icon.png';
import iconRuStore from '../images/platform/ru-store-icon.png';
import iconNashStore from '../images/platform/nash-store-icon.png';

export const Games = {
    [AppLocale.EN]: {
        chess: {
            logo: chessCover,
            title: 'title/chess',
            mainPlatformCode: 'yandexGames',
            platforms: {
                yandexGames: {
                    icon: iconYg,
                    url: 'https://yandex.ru/games/app/228794?utm_source=distrib&utm_medium=delank-myhd&clid=2968864',
                },
                crazyGames: {
                    icon: iconCrazyGames,
                    url: 'https://www.crazygames.com/game/chess-free',
                },
                vk: {
                    icon: iconVk,
                    url: 'https://vk.com/app51798267',
                },
                ok: {
                    icon: iconOk,
                    url: 'https://ok.ru/game/chess-free',
                },
                gameMonetize: {
                    icon: iconGM,
                    url: 'https://html5.gamemonetize.com/u3vfu3ocwhdperazqcfr292brdp07pau/',
                },
                rustore: {
                    icon: iconRuStore,
                    url: 'https://www.rustore.ru/catalog/app/com.smartberry.chess',
                },
                googlePlay: {
                    icon: iconGooglePlay,
                    url: 'https://play.google.com/store/apps/details?id=com.smartberry.chess',
                },
                gamePix: {
                    icon: iconGamePix,
                    url: 'https://www.gamepix.com/play/chess-for-free',
                },
                poki: {
                    icon: iconPoki,
                    url: 'https://poki.com/ru/g/chess-free',
                },
            },
        },
        checkersEn: {
            logo: checkersEnCoverWithEnTitle,
            title: 'title/checkersEn',
            mainPlatformCode: 'crazyGames',
            platforms: {
                crazyGames: {
                    icon: iconCrazyGamesLargePurple,
                    url: 'https://www.crazygames.com/game/checkers-free',
                },
            },
        },
        checkersRu: {
            logo: checkersRuCoverWithEnTitle,
            title: 'title/checkersRu',
            mainPlatformCode: 'crazyGames',
            platforms: {
                crazyGames: {
                    icon: iconCrazyGames,
                    url: 'https://www.crazygames.com/game/russian-checkers-free',
                },
                yandexGames: {
                    icon: iconYg,
                    url: 'https://yandex.ru/games/app/364458?utm_source=distrib&utm_medium=delank-myhd&clid=10371474',
                },
                vk: {
                    icon: iconVk,
                    url: 'https://vk.com/games/app52172230',
                },
                ok: {
                    icon: iconOk,
                    url: 'https://ok.ru/game/512002818335',
                },
                gameMonetize: {
                    icon: iconGM,
                    url: 'https://gamemonetize.com/russian-checkers-game',
                },
                rustore: {
                    icon: iconRuStore,
                    url: 'https://www.rustore.ru/catalog/app/com.smartberry.checkers_ru',
                },
                googlePlay: {
                    icon: iconGooglePlay,
                    url: 'https://play.google.com/store/apps/details?id=com.smartberry.checkers_ru',
                },
            },
        },
        petsAndPaints: {
            logo: petsAndPaintsCover,
            title: 'title/petsAndPaints',
            mainPlatformCode: 'yandexGames',
            platforms: {
                crazyGames: {
                    icon: iconCrazyGames,
                    url: 'https://www.crazygames.com/game/timberland-arrange-puzzle-game',
                },
                yandexGames: {
                    icon: iconYg,
                    url: 'https://yandex.ru/games/app/225080?utm_source=distrib&utm_medium=delank-myhd&clid=9491731',
                },
                vk: {
                    icon: iconVk,
                    url: 'https://vk.com/games/app52094267',
                },
                ok: {
                    icon: iconOk,
                    url: 'https://ok.ru/game/512001416278',
                },
                gameMonetize: {
                    icon: iconGM,
                    url: 'https://html5.gamemonetize.com/xspq2s1j6u6sf4o5v1gy5dnhqcs78d3n/',
                },
                rustore: {
                    icon: iconRuStore,
                    url: 'https://apps.rustore.ru/app/com.delank.timberland',
                },
                googlePlay: {
                    icon: iconGooglePlay,
                    url: 'https://play.google.com/store/apps/details?id=com.delank.timberland',
                },
                appStore: {
                    icon: iconAppStore,
                    url: 'https://apps.apple.com/by/app/timberland-puzzle-game/id1563556047',
                },
                appGallery: {
                    icon: iconAppGallery,
                    url: 'https://appgallery.cloud.huawei.com/ag/n/app/C107157987',
                },
                nashStore: {
                    icon: iconNashStore,
                    url: 'https://store.nashstore.ru/store/62864512fb3ed3501d52cfc0',
                },
            },
        },
        marketplace: {
            logo: marketplaceCover,
            title: 'title/marketplace',
            mainPlatformCode: 'yandexGames',
            platforms: {
                yandexGames: {
                    icon: iconYg,
                    url: 'https://yandex.ru/games/app/337436?utm_source=distrib&utm_medium=delank-myhd&clid=9491668',
                },
                vk: {
                    icon: iconVk,
                    url: 'https://vk.com/games/app52018804',
                },
            },
        },
        retroRacerTurbo: {
            logo: retroRacerTurboCover,
            title: 'title/retroRacerTurbo',
            mainPlatformCode: 'yandexGames',
            platforms: {
                yandexGames: {
                    icon: iconYg,
                    url: 'https://yandex.ru/games/app/264148?utm_source=distrib&utm_medium=delank-myhd',
                },
            },
        },
    },

    [AppLocale.RU]: {
        checkersRu: {
            logo: checkersRuCoverWithRuTitle,
            title: 'title/checkersRu',
            mainPlatformCode: 'yandexGames',
            platforms: {
                yandexGames: {
                    icon: iconYg,
                    url: 'https://yandex.ru/games/app/364458?utm_source=distrib&utm_medium=delank-myhd&clid=10371474',
                },
                vk: {
                    icon: iconVk,
                    url: 'https://vk.com/games/app52172230',
                },
                ok: {
                    icon: iconOk,
                    url: 'https://ok.ru/game/512002818335',
                },
                gameMonetize: {
                    icon: iconGM,
                    url: 'https://gamemonetize.com/russian-checkers-game',
                },
                rustore: {
                    icon: iconRuStore,
                    url: 'https://www.rustore.ru/catalog/app/com.smartberry.checkers_ru',
                },
                googlePlay: {
                    icon: iconGooglePlay,
                    url: 'https://play.google.com/store/apps/details?id=com.smartberry.checkers_ru',
                },
                crazyGames: {
                    icon: iconCrazyGames,
                    url: 'https://www.crazygames.com/game/russian-checkers-free',
                },
            },
        },
        chess: {
            logo: chessRuCover,
            title: 'title/chess',
            mainPlatformCode: 'yandexGames',
            platforms: {
                yandexGames: {
                    icon: iconYg,
                    url: 'https://yandex.ru/games/app/228794?utm_source=distrib&utm_medium=delank-myhd&clid=2968864',
                },
                vk: {
                    icon: iconVk,
                    url: 'https://vk.com/app51798267',
                },
                ok: {
                    icon: iconOk,
                    url: 'https://ok.ru/game/chess-free',
                },
                crazyGames: {
                    icon: iconCrazyGames,
                    url: 'https://www.crazygames.com/game/chess-free',
                },
                gameMonetize: {
                    icon: iconGM,
                    url: 'https://html5.gamemonetize.com/u3vfu3ocwhdperazqcfr292brdp07pau/',
                },
                rustore: {
                    icon: iconRuStore,
                    url: 'https://www.rustore.ru/catalog/app/com.smartberry.chess',
                },
                googlePlay: {
                    icon: iconGooglePlay,
                    url: 'https://play.google.com/store/apps/details?id=com.smartberry.chess',
                },
                gamePix: {
                    icon: iconGamePix,
                    url: 'https://www.gamepix.com/play/chess-for-free',
                },
                poki: {
                    icon: iconPoki,
                    url: 'https://poki.com/ru/g/chess-free',
                },
            },
        },
        checkersEn: {
            logo: checkersEnCover,
            title: 'title/checkersEn',
            mainPlatformCode: 'crazyGames',
            platforms: {
                crazyGames: {
                    icon: iconCrazyGamesLargePurple,
                    url: 'https://www.crazygames.com/game/checkers-free',
                },
            },
        },
        petsAndPaints: {
            logo: petsAndPaintsCover,
            title: 'title/petsAndPaints',
            mainPlatformCode: 'yandexGames',
            platforms: {
                yandexGames: {
                    icon: iconYg,
                    url: 'https://yandex.ru/games/app/225080?utm_source=distrib&utm_medium=delank-myhd&clid=9491731',
                },
                vk: {
                    icon: iconVk,
                    url: 'https://vk.com/games/app52094267',
                },
                ok: {
                    icon: iconOk,
                    url: 'https://ok.ru/game/512001416278',
                },
                gameMonetize: {
                    icon: iconGM,
                    url: 'https://html5.gamemonetize.com/xspq2s1j6u6sf4o5v1gy5dnhqcs78d3n/',
                },
                crazyGames: {
                    icon: iconCrazyGames,
                    url: 'https://www.crazygames.com/game/timberland-arrange-puzzle-game',
                },
                rustore: {
                    icon: iconRuStore,
                    url: 'https://apps.rustore.ru/app/com.delank.timberland',
                },
                googlePlay: {
                    icon: iconGooglePlay,
                    url: 'https://play.google.com/store/apps/details?id=com.delank.timberland',
                },
                appStore: {
                    icon: iconAppStore,
                    url: 'https://apps.apple.com/by/app/timberland-puzzle-game/id1563556047',
                },
                appGallery: {
                    icon: iconAppGallery,
                    url: 'https://appgallery.cloud.huawei.com/ag/n/app/C107157987',
                },
                nashStore: {
                    icon: iconNashStore,
                    url: 'https://store.nashstore.ru/store/62864512fb3ed3501d52cfc0',
                },
            },
        },
        marketplace: {
            logo: marketplaceCover,
            title: 'title/marketplace',
            mainPlatformCode: 'yandexGames',
            platforms: {
                yandexGames: {
                    icon: iconYg,
                    url: 'https://yandex.ru/games/app/337436?utm_source=distrib&utm_medium=delank-myhd&clid=9491668',
                },
                vk: {
                    icon: iconVk,
                    url: 'https://vk.com/games/app52018804',
                },
            },
        },
        retroRacerTurbo: {
            logo: retroRacerTurboCover,
            title: 'title/retroRacerTurbo',
            mainPlatformCode: 'yandexGames',
            platforms: {
                yandexGames: {
                    icon: iconYg,
                    url: 'https://yandex.ru/games/app/264148?utm_source=distrib&utm_medium=delank-myhd',
                },
            },
        },
    },
};

export const Smartberry = {
    email: 'smartberry@mail.ru',
    platforms: {
        Vk: {
            logo: iconVk,
            url: 'https://vk.com/smartberry_games',
        },
        Ok: {
            logo: iconOk,
            url: 'https://ok.ru/group/70000005108745',
        },
    },
};
