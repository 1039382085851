const appTranslations = {
    en: {
        companyName: 'Smartberry Games',

        'title/chess': 'Chess',
        'title/checkersEn': 'English Checkers',
        'title/checkersRu': 'Russian Checkers',
        'title/petsAndPaints': 'Timberland Arrange Puzzle',
        'title/marketplace': 'Marketplace',
        'title/retroRacerTurbo': 'Retro Racer Turbo',

        play: 'Play on',

        'text/weDevelopAndPublish': 'We develop and publish smart web games',
        'text/weHelpYouReleaseGame': 'We will help you release your game on 15+ platforms',
        'text/toCooperateWriteTo': 'For cooperation, write to',
    },
    ru: {
        companyName: 'Смартбери Игры',

        'title/chess': 'Шахматы Ру',
        'title/checkersEn': 'Английские Шашки',
        'title/checkersRu': 'Шашки Ру',
        'title/petsAndPaints': 'Головоломка Звери и краски',
        'title/marketplace': 'Маркет',
        'title/retroRacerTurbo': 'Retro Racer Turbo',

        play: 'Играть',

        'text/weDevelopAndPublish': 'Разрабатываем и публикуем интеллектуальные веб-игры',
        'text/weHelpYouReleaseGame': 'Поможем выпустить вашу игру на 15+ площадках',
        'text/toCooperateWriteTo': 'По вопросам сотрудничества пишите на',

        'text/weDevelopAndPublishAndHelp':
            'Разрабатываем и публикуем интеллектуальные веб-игры. ' +
            'Поможем выпустить вашу игру на 15+ площадках. По вопросам сотрудничества пишите на',
    },
};

export default appTranslations;
