export class YandexMetrica {
    // eslint-disable-next-line no-undef
    static metricId = YANDEX_METRICA_ID;

    static trackGoal(goalAlias = '', params = {}) {
        /*console.log({
            target: goalAlias,
            params: { [goalAlias]: params },
        })*/
        // eslint-disable-next-line no-undef
        ym(YandexMetrica.metricId, 'reachGoal', goalAlias, { [goalAlias]: params });
    }

    static gameClickPlay({ game, platform, linkType }) {
        YandexMetrica.trackGoal('click_play', {
            game_name: game,
            platform: platform,
            link_type: linkType,
        });
    }

    static Game = {
        chess: 'chess',
        checkersEn: 'checkers_en',
        checkersRu: 'checkers_ru',
        petsAndPaints: 'pets_and_paints',
        marketplace: 'market',
        retroRacerTurbo: 'retro_racer_turbo',
    };

    static LinkType = {
        platformIcon: 'platform_icon', // кликнуто по иконке платформы
        cover: 'cover', // кликнуто по обложке игры
    };

    // https://docs.gamepush.com/ru/docs/platforms/#коды-площадок
    static Platform = {
        crazyGames: 'CRAZY_GAMES',
        gameDistribution: 'GAME_DISTRIBUTION',
        gameMonetize: 'GAME_MONETIZE',
        ok: 'OK',
        smartMarket: 'SMARTMARKET',
        vk: 'VK',
        yandexGames: 'YANDEX',
        gamePix: 'GAMEPIX',
        poki: 'POKI',
        nashStore: 'NASH_STORE',
        vkPlay: 'VK_PLAY',
        wgPlayground: 'WG_PLAYGROUND',
        kongregate: 'KONGREGATE',
        googlePlay: 'GOOGLE_PLAY',
        appStore: 'APP_STORE',
        playDeck: 'PLAYDECK',
        appGallery: 'APP_GALLERY',
        galaxyStore: 'GALAXY_STORE',
        oneStore: 'ONE_STORE',
        amazonAppStore: 'AMAZON_APPSTORE',
        xiaomiGetApps: 'XIAOMI_GETAPPS',
        aptoide: 'APTOIDE',
        rustore: 'RUSTORE',
        android: 'ANDROID',
    };
}
