import { Image } from '@mantine/core';

export const ImageReDirect = ({ src = '', url = '', style, ...rest }) => {
    return (
        <a target="_blank" href={url}>
            <Image
                src={src}
                style={{
                    cursor: 'pointer',
                    borderRadius: '8px',
                    ...style,
                }}
                {...rest}
            />
        </a>
    );
};
