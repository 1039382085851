import { Header } from './components/Header/Header';
import { AppShell, MantineProvider } from '@mantine/core';
import { Footer } from './components/Footer/Footer';
import { useViewportSize } from '@mantine/hooks';
import style from './App.module.scss';
import Main from './components/Main/Main';

function App() {
    const { height, width } = useViewportSize();

    return (
        <MantineProvider withGlobalStyles defaultColorScheme="dark">
            <AppShell
                className={'App'}
                header={{ height: width <= 580 ? '3rem' : '5rem' }}
                footer={{ height: '3rem' }}
            >
                <div className={style.App__PageContent}>
                    <AppShell.Header className={style.App__Header}>
                        <Header />
                    </AppShell.Header>
                    <AppShell.Main className={style.App__Main}>
                        <Main />
                    </AppShell.Main>
                    <AppShell.Footer className={style.App__Footer}>
                        <Footer />
                    </AppShell.Footer>
                </div>
            </AppShell>
        </MantineProvider>
    );
}

export default App;
