import React from 'react';

import { Container, Flex } from '@mantine/core';

import { Games } from '../../constant/constant';
import style from './Cards.module.scss';
import Card from '../Card/Card';
import { selectedAppLocale } from '../../language/translations';

export const Cards = () => {
    const games = Games[selectedAppLocale] ?? {};

    return (
        <Container fluid pt={16} pb={32}>
            <Flex justify="center" wrap="wrap" gap={32} className={style.cards}>
                {Object.entries(games).map(([gameCode, game]) => (
                    <Card key={gameCode} game={game} gameCode={gameCode} />
                ))}
            </Flex>
        </Container>
    );
};
