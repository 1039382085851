import { Flex, Image, Text } from '@mantine/core';
import logoCompany from '../../images/smartberry-logo.png';
import { t } from '../../language/translations';

export function Header() {
    return (
        <Flex align={'center'} justify={'center'} h={'100%'} gap={16} pb={4} pt={4}>
            <Image src={logoCompany} mah={'75%'} /> <Text fz={24}>{t('companyName')}</Text>{' '}
        </Flex>
    );
}
